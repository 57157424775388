<template>
    <div class="form_ct flex">
        <p class="upLoad_text">{{ title }} ：</p>
        <div class="multiterm_box">
            <div v-for="(item, index) in inputArr" :key="index" style="position: relative;">
                <el-input @blur="postArr"  class="upload_input" v-model="item.value" :placeholder="input_placeholder"></el-input>
                <div class="add_delete_icon_box">
                    <i class="add" @click="add" v-show="index == 0"></i>
                    <i class="delete" v-show="index > 0" @click="de_lete(index)"></i>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        input_placeholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            inputArr: [{
                value: ''
            }, {
                value: ''
            }],
        }
    },
    methods: {
        add() {
            this.inputArr.push({
                value: ''
            })
        },
        de_lete(index) {
            this.inputArr.splice(index, 1)
        },
        postArr(){
            this.$emit('postArr',this.inputArr)
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>