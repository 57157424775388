<template>
    <div>
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI访谈生成" />

                        <div class="form_ct flex">
                            <p class="upLoad_text">访谈主题 ：</p>
                            <div class="upload_box">
                                <el-input v-model="form.interview_theme" placeholder="请输入访谈主题标题"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">访谈对象：</p>
                            <div class="upload_box">
                                <el-input v-model="form.interview_object" placeholder="请输入访谈对象名称"></el-input>
                            </div>
                        </div>
                        <ai_select selectTitle="访谈形式" :select_list="interview_modality_list" @getSelectVal="get_interview_modality" />
                        <ai_select selectTitle="照目分类" :select_list="classify_list" @getSelectVal="get_classify" />
                        <ai_multiterm title="访谈问题" input_placeholder="请输入具体问题" @postArr="postArr" />
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
import ai_multiterm from '@/components/ai_multiterm.vue'
export default {
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        wordNav,
        ai_select,
        ai_multiterm,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                interview_theme: '', //  # 访谈主题
                interview_object: '',  //# 访谈对象
                interview_modality: '',  //# 访谈形式
                interview_classify: '',  //# 访谈分类
                interview_answer: []  //# 访谈问题(数组类型)
            },
            interview_modality_list: [
                '面对面', '电话', '网络'
            ],
            classify_list:['结构性','非结构性','半结构化'],
            contentData: {}, //内容
            selectList: ['美食', '自然', '专题', '历史', '生活', '宣传', '其他'],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_interview_modality(val) {
            this.form.interview_modality = val
        },
        get_classify(val) {
            this.form.interview_classify = val
        },
        postArr(arr) {
            this.form.interview_answer = arr
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            this.curlPost('/file/create_interview', {
                interview_theme: this.form.interview_theme,
                interview_object: this.form.interview_object,
                interview_modality: this.form.interview_modality,
                interview_classify: this.form.interview_classify,
                interview_answer: this.form.interview_answer,
                mobile: this.$user_info.mobile,
            }).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>